<template>
    <div id="rebate-payment" class="newRebatePayment">
        <b-container>
            <h1 v-if="!disableRequestorFields && !$route.query.id">New Rebate Payment</h1>
            <div class="request-header" v-if="(sendBacktoRequestor || disableRequestorFields)">
                <h1>Rebate Payment {{form.rebateNumber}}</h1>

                <b-button class="btn-yellow btn-save-changes btn-save-pdf" v-if="$route.query.id" v-on:click="saveToPdf()">
                    Save to PDF
                </b-button>
            </div>
            <div>
                <p v-if="currentStatus" class="small"><em>Current status: {{currentStatus}}</em></p>
            </div>
            <b-form @submit.prevent="onSubmit">
                <div v-bind:class="{ requestorSection: disableRequestorFields }">
                    <h2>Request</h2>
                    <label for="rebate-number">Rebate Agreement Number:</label>
                    <b-input-group id="rebate-number-group">
                        <b-form-input
                            id="rebate-number"
                            v-model="form.rebateNumber"
                            type="number"
                            :disabled="disableRequestorFields"
                            pattern="/^-?\d+\.?\d*$/"
                            :formatter="formatRebateNumber"
                        ></b-form-input>
                        <b-input-group-append>
                            <!--<b-button type="submit" class="btn-yellow btn-rebate-search" :disabled="disableRequestorFields">Search</b-button>-->
                            <b-button type="button" class="btn-yellow btn-rebate-search" v-on:click="getRebateInfo()" :disabled="disableRequestorFields">
                                Search
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>

                    <b-overlay :show="searchLoading" rounded="sm" class="table-sap-data-overlay">
                        <b-table-simple small responsive stacked striped class="table-sap-data">
                            <b-tbody>
                                <b-tr>
                                    <b-td stacked-heading="Rebate Agreement Description:">
                                        <b-form-input
                                            id="rebate-description"
                                            v-model="form.rebateDescription"
                                            :disabled=true
                                        ></b-form-input>
                                    </b-td>
                                    <b-td stacked-heading="Common Owner #:">
                                        <b-form-input
                                            id="common-owner-num"
                                            v-model="form.commonOwnerNumber"
                                            :disabled=true
                                        ></b-form-input>
                                    </b-td>
                                    <b-td stacked-heading="Common Owner Name:">
                                        <b-form-input
                                            id="common-owner-name"
                                            v-model="form.commonOwnerName"
                                            :disabled=true
                                        ></b-form-input>
                                    </b-td>
                                    <b-td stacked-heading="Sub Common Owner #:">
                                        <b-form-input
                                            id="subcommon-owner-num"
                                            v-model="form.subcommonOwnerNumber"
                                            :disabled=true
                                        ></b-form-input>
                                    </b-td>
                                    <b-td stacked-heading="Customer Group:">
                                        <b-form-input
                                            id="customer-group"
                                            v-model="form.customerGroup"
                                            :disabled=true
                                        ></b-form-input>
                                    </b-td>
                                    <b-td stacked-heading="Rebate PBU:">
                                        <b-form-input
                                            id="rebate-pbu"
                                            v-model="$v.form.rebatePbu.$model"
                                            :disabled=true
                                            v-if="isRebatePBUValidInSAP"
                                        ></b-form-input>
                                        <b-form-select
                                            id="rebate-pbu-select"
                                            ref="rebatePbu"
                                            v-model="$v.form.rebatePbu.$model"
                                            v-if="!isRebatePBUValidInSAP"
                                        >
                                            <b-form-select-option :value="null">Please select...</b-form-select-option>
                                            <b-form-select-option value="OTR">OTR</b-form-select-option>
                                            <b-form-select-option value="CONSUMER">Consumer</b-form-select-option>
                                            <b-form-select-option value="COMMERCIAL">Commercial</b-form-select-option>
                                            <b-form-select-option value="CANADA">Canada</b-form-select-option>
                                        </b-form-select>
                                        <div class="error small validation-error" v-if="$v.form.rebatePbu.$error && !$v.form.rebatePbu.required">Rebate PBU is required</div>
                                    </b-td>
                                    <b-td stacked-heading="Payer Nonsig:">
                                        <b-form-input
                                            id="payer-nonsig"
                                            v-model="form.payerNonsig"
                                            :disabled=true
                                        ></b-form-input>
                                    </b-td>
                                    <b-td stacked-heading="Valid From Date:">
                                        <b-form-input
                                            id="valid-from-date"
                                            v-model="form.validFromDate"
                                            :disabled=true
                                        ></b-form-input>
                                    </b-td>
                                    <b-td stacked-heading="Valid To Date:">
                                        <b-form-input
                                            id="valid-to-date"
                                            v-model="form.validToDate"
                                            :disabled=true
                                        ></b-form-input>
                                    </b-td>
                                    <b-td stacked-heading="Payment Period:">
                                        <b-form-input
                                            id="payment-period"
                                            v-model="form.paymentPeriod"
                                            :disabled=true
                                            v-if="disableRequestorFields"
                                        ></b-form-input>
                                        <b-form-select
                                            id="payment-period-select"
                                            ref="paymentPeriod"
                                            v-model="form.paymentPeriod"
                                            v-if="!disableRequestorFields"
                                        >
                                            <b-form-select-option :value="null">Please select...</b-form-select-option>
                                            <b-form-select-option value="Q1">Q1</b-form-select-option>
                                            <b-form-select-option value="Q2">Q2</b-form-select-option>
                                            <b-form-select-option value="Q3">Q3</b-form-select-option>
                                            <b-form-select-option value="Q4">Q4</b-form-select-option>
                                            <b-form-select-option value="1st Half">1st Half</b-form-select-option>
                                            <b-form-select-option value="2nd Half">2nd Half</b-form-select-option>
                                            <b-form-select-option value="Jan">January</b-form-select-option>
                                            <b-form-select-option value="Feb">February</b-form-select-option>
                                            <b-form-select-option value="Mar">March</b-form-select-option>
                                            <b-form-select-option value="Apr">April</b-form-select-option>
                                            <b-form-select-option value="May">May</b-form-select-option>
                                            <b-form-select-option value="Jun">Jun</b-form-select-option>
                                            <b-form-select-option value="July">July</b-form-select-option>
                                            <b-form-select-option value="Aug">August</b-form-select-option>
                                            <b-form-select-option value="Sept">September</b-form-select-option>
                                            <b-form-select-option value="Oct">October</b-form-select-option>
                                            <b-form-select-option value="Nov">November</b-form-select-option>
                                            <b-form-select-option value="Dec">December</b-form-select-option>
                                            <b-form-select-option value="ANNUAL">ANNUAL</b-form-select-option>
                                            <b-form-select-option value="Other">Other</b-form-select-option>
                                        </b-form-select>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-overlay>
                    <br>
                    <b-form-group 
                        label="What type of payment is this?"
                        :class="{ 'form-group--error': $v.form.selectedPaymentType.$error }"
                    >
                        <div class="error small" v-if="$v.form.selectedPaymentType.$error && !$v.form.selectedPaymentType.required">Payment type is required</div>
                        <b-form-radio v-model="$v.form.selectedPaymentType.$model" name="payment-type-radios" value="Final" :checked="form.selectedPaymentType == 'Final'" :disabled="disableRequestorFields">Final</b-form-radio>
                        <b-form-radio v-model="$v.form.selectedPaymentType.$model" name="payment-type-radios" value="Partial" :checked="form.selectedPaymentType == 'Partial'" :disabled="disableRequestorFields">Partial</b-form-radio>
                    </b-form-group>

                    <b-form-group 
                        id="rebate-title" 
                        label="*Rebate Title:" 
                        label-for="rebate-title" 
                        :class="{ 'form-group--error': $v.form.rebateTitle.$error }"
                    >
                        <b-form-input
                            id="rebate-title"
                            v-model="$v.form.rebateTitle.$model"
                            :disabled="disableRequestorFields"
                        ></b-form-input>
                        <div class="error small" v-if="$v.form.rebateTitle.$error && !$v.form.rebateTitle.required">Rebate title is required</div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-payment-amount"
                        label="*Payment Amount:"
                        label-for="payment-amount"
                        :class="{ 'form-group--error': $v.form.paymentAmount.$error }"
                    >
                        <b-form-input
                            id="payment-amount"
                            v-model="$v.form.paymentAmount.$model"
                            :disabled="disableRequestorFields"
                            type="number"
                            step="any"
                        ></b-form-input>
                        <div class="error small" v-if="$v.form.paymentAmount.$error && !$v.form.paymentAmount.required">Payment amount is required</div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-credit-memo"
                        label="*Credit Memo Text:"
                        label-for="credit-memo"
                        :class="{ 'form-group--error': $v.form.creditMemo.$error }"
                    >
                        <b-form-textarea
                            id="credit-memo"
                            v-model="$v.form.creditMemo.$model"
                            :disabled="disableRequestorFields"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                        <div class="error small" v-if="$v.form.creditMemo.$error && !$v.form.creditMemo.required">Credit Memo is required</div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-documentation"
                        label="Attach Supporting Documentation (optional)"
                        label-for="supporting-documentation"
                    >
                        <div class="small"><em>To upload more than 1 file, select both files together at the same time while browsing</em></div>
                        <b-form-file
                            v-model="form.file"
                            :disabled="disableRequestorFields"
                            ref="file-input" 
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            multiple
                        ></b-form-file>
                        <div v-if="form.file && form.file.length > 0">
                            <a @click="clearFile1" class="clear-files" tabindex="0">
                            <font-awesome-icon icon="minus-circle" /> Remove attachment(s)</a>
                        </div> 
                    </b-form-group>
                    <b-form-group>
                        <b-list-group>
                        <b-list-group-item
                            button
                            href="#"
                            v-for="item in items"
                            v-bind:key="item.key"
                            v-on:click="onFileClick(item)"
                        >
                            <div>
                            <small>{{ item.key }}</small>
                            </div>
                        </b-list-group-item>
                        </b-list-group> 
                    </b-form-group>

                    <b-form-group
                        id="input-group-notes"
                        label="Additional Notes (optional)"
                        label-for="additional-notes"
                    >
                        <b-form-textarea
                        id="additional-notes"
                        v-model="form.additionalNotes"
                        :disabled="disableRequestorFields"
                        rows="3"
                        max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                    <p v-if="disableRequestorFields" class="small"><em>{{requestorStatus}}</em></p>
                </div>

                <div class="recall-button" v-if="this.requestInfo.requestor_user_id === this.userSession.userid && this.requestInfo.form_state != 100 && currentStatus !== 'Complete'">
                    <b-button class="btn-yellow btn-save-changes" v-on:click="recallRequest()">
                        <div class="spinner-border spinner-border-sm" v-if="recallLoading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Recall Payment
                    </b-button>
                </div>

                <div v-if="disableRequestorFields || sendBacktoRequestor" v-bind:class="{ approverSection: disableApproverFields }">
                    <h2>Rebate Approver</h2>
                    <b-form-group
                        :class="{ 'form-group--error': $v.form.approval1Type.$error }"
                    >
                        <b-form-radio v-model="$v.form.approval1Type.$model" name="approval1Types" value="Approve" :disabled="disableApproverFields">Approve</b-form-radio>
                        <b-form-radio v-model="$v.form.approval1Type.$model" name="approval1Types" value="ApproveWF" :disabled="disableApproverFields">Approve with Funding</b-form-radio>
                        <b-form-radio v-model="$v.form.approval1Type.$model" name="approval1Types" value="ApproveWR" :disabled="disableApproverFields">Approve with Return</b-form-radio>
                        <b-form-radio v-model="$v.form.approval1Type.$model" name="approval1Types" value="Deny" :disabled="disableApproverFields">Deny</b-form-radio>
                        <b-form-radio v-model="$v.form.approval1Type.$model" name="approval1Types" value="SendBack" :disabled="disableApproverFields">Send Back to Requestor</b-form-radio>
                        <div class="error small" v-if="$v.form.approval1Type.$error && !$v.form.approval1Type.required">Action type is required</div>
                    </b-form-group>

                    <hr>
                    <b-form-group
                        id="input-group-close-rebate"
                        v-if="form.approval1Type == 'Approve' || form.approval1Type == 'ApproveWF' || form.approval1Type == 'ApproveWR'"
                    >
                        <b-form-checkbox
                            v-model="form.closeRebate"
                            :disabled="disableApproverFields"
                            name="checkbox-1"
                            value="Yes"
                            unchecked-value="No"
                            >
                            Close rebate agreement?
                        </b-form-checkbox>
                    </b-form-group>

                    <b-form-group
                        id="input-group-funding-amount"
                        label="Funding Amount:"
                        label-for="funding-amount"
                        v-if="form.approval1Type == 'ApproveWF'"
                    >
                        <b-form-input
                        id="funding-amount"
                        v-model="form.fundingAmount"
                        :disabled="disableApproverFields"
                        type="number"
                        step="any"
                        required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-group-return-amount"
                        label="Return Amount:"
                        label-for="return-amount"
                        v-if="form.approval1Type == 'ApproveWR'"
                    >
                        <b-form-input
                            id="return-amount"
                            v-model="form.returnAmount"
                            :disabled="disableApproverFields"
                            type="number"
                            step="any"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-group-send-back-reason"
                        label="*Reason for Sending Back to Requestor:"
                        label-for="send-back-reason"
                        v-if="form.approval1Type == 'SendBack'"
                        :class="{ 'form-group--error': $v.form.sendBackReason.$error }"
                    >
                        <b-form-textarea
                            id="send-back-reason"
                            v-model="$v.form.sendBackReason.$model"
                            :disabled="disableApproverFields"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                        <div class="error small" v-if="$v.form.sendBackReason.$error && !$v.form.sendBackReason.required">Reason for Sending Back to Requestor is required</div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-deny-reason"
                        label="*Reason for Denying:"
                        label-for="deny-reason"
                        v-if="form.approval1Type == 'Deny'"
                        :class="{ 'form-group--error': $v.form.denyReason.$error }"
                    >
                        <b-form-textarea
                            id="deny-reason"
                            v-model="$v.form.denyReason.$model"
                            :disabled="disableApproverFields"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                        <div class="error small" v-if="$v.form.denyReason.$error && !$v.form.denyReason.required">Reason for Denying is required</div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-approver-additional-notes"
                        label="Additional Notes from Approver:"
                        label-for="approver-additional-notes"
                    >
                        <b-form-textarea
                            id="approver-additional-notes"
                            v-model="form.approverAdditionalNotes"
                            :disabled="disableApproverFields"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                    <div>
                        <p class="small"><em>{{approverStatus}}</em></p>
                    </div>
                </div>

                <div v-if="requestId" class="gbsSection">
                    <b-form-group
                        id="input-group-invoice-document"
                        label="Invoice Document:"
                        label-for="invoice-document"
                    >
                        <b-form-input
                            id="invoice-number"
                            v-model="form.invoiceDocument"
                            :disabled="((disableGBSFields || (!disableGBSFields && hideGBSFields)) && disableApproverFields) || sendBacktoRequestor"
                        ></b-form-input>
                    </b-form-group>
                </div>

                <div v-if="!hideGBSFields" class="gbsSection" v-bind:class="{ approverSection: disableGBSFields }">
                    <h2>GBS Support & Control</h2>
                    <b-form-group
                        id="input-group-sales-order-num"
                        label="*Sales Order Number:"
                        label-for="sales-order-num"
                        :class="{ 'form-group--error': $v.form.salesOrderNumber.$error }"
                   >
                        <b-form-input
                            id="sales-order-num"
                            v-model="$v.form.salesOrderNumber.$model"
                            :disabled="disableGBSFields"
                        ></b-form-input>
                        <div class="error small" v-if="$v.form.salesOrderNumber.$error && !$v.form.salesOrderNumber.required">Sales Order Number is required</div>
                    </b-form-group>
                    <b-form-group
                        id="input-group-invoice-number"
                        label="*Invoice Number:"
                        label-for="invoice-number"
                        :class="{ 'form-group--error': $v.form.invoiceNumber.$error }"
                    >
                        <b-form-input
                            id="invoice-number"
                            v-model="$v.form.invoiceNumber.$model"
                            :disabled="disableGBSFields"
                        ></b-form-input>
                        <div class="error small" v-if="$v.form.invoiceNumber.$error && !$v.form.invoiceNumber.required">Invoice Number is required</div>
                    </b-form-group>
                    <b-form-group label="If requested, has the rebate been closed in SAP?" v-if="form.closeRebate == 'closeRebateYes'">
                        <b-form-radio v-model="form.closedInSAP" name="closedInSAP" value="Yes">Yes</b-form-radio>
                        <b-form-radio v-model="form.closedInSAP" name="closedInSAP" value="No">Not requested to be closed</b-form-radio>
                    </b-form-group>

                    <b-form-group
                        id="input-group-gbs-notes"
                        label="Additional Notes"
                        label-for="additional-gbs-notes"
                    >
                        <b-form-textarea
                        id="additional-gbs-notes"
                        v-model="form.additionalGbsNotes"
                        :disabled="disableGBSFields"
                        rows="3"
                        max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>

                    <b-form-group
                        :class="{ 'form-group--error': $v.form.approval2Type.$error }"
                    >
                        <b-form-radio v-model="$v.form.approval2Type.$model" name="approval2Types" value="Approve" :disabled="disableGBSFields">Approve</b-form-radio>
                        <b-form-radio v-model="$v.form.approval2Type.$model" name="approval2ypes" value="SendBack" :disabled="disableGBSFields">Send Back to Requestor</b-form-radio>
                        <b-form-radio v-model="$v.form.approval2Type.$model" name="approval2ypes" value="SendBackApprover" :disabled="disableGBSFields">Send Back to Approver</b-form-radio>
                        <div class="error small" v-if="$v.form.approval2Type.$error && !$v.form.approval2Type.required">Action type is required</div>
                    </b-form-group>

                     <b-form-group
                        id="input-group-gbs-sendback-reason"
                        label="*Reason for Sending Back:"
                        label-for="gbs-sendback-reason"
                        v-if="form.approval2Type == 'SendBack' || form.approval2Type == 'SendBackApprover'"
                        :class="{ 'form-group--error': $v.form.gbsSendbackReason.$error }"
                    >
                        <b-form-textarea
                            id="gbs-sendback-reason"
                            v-model="$v.form.gbsSendbackReason.$model"
                            :disabled="disableGBSFields"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                        <div class="error small" v-if="$v.form.gbsSendbackReason.$error && !$v.form.gbsSendbackReason.required">Reason for Sending Back is required</div>
                    </b-form-group>
                    <div>
                        <p class="small"><em>{{gbsStatus}}</em></p>
                    </div>
                </div>
                <div class="btn-actions-container">
                    <b-button type="submit" class="btn-yellow" v-if="!sendBacktoRequestor && !disableRequestorFields">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="loading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Submit Payment
                    </b-button>
                    <b-button type="submit" class="btn-yellow" v-if="sendBacktoRequestor">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="loading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Resubmit Payment
                    </b-button>
                    <b-button class="btn-yellow" v-if="!sendBacktoRequestor && disableRequestorFields && !disableApproverFields" v-on:click="approverSubmit">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="loading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Submit
                    </b-button>
                    <b-button class="btn-yellow btn-save-changes" v-if="!sendBacktoRequestor && disableRequestorFields && !disableApproverFields" v-on:click="saveChanges('approver')">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="saveChangesLoading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Save Changes
                    </b-button>
                    <b-button class="btn-yellow" v-if="!sendBacktoRequestor && disableApproverFields && !hideGBSFields && !disableGBSFields" v-on:click="gbsSubmit">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="loading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Submit
                    </b-button>
                    <b-button class="btn-yellow btn-save-changes" v-if="!sendBacktoRequestor && disableApproverFields && !hideGBSFields && !disableGBSFields" v-on:click="saveChanges('gbs')">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="saveChangesLoading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Save Changes
                    </b-button>
                    <b-button class="btn-yellow btn-save-changes btn-attachments" v-if="$route.query.id && items.length > 0" v-on:click="downloadAllAttachments()">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="downloadAttachmentsLoading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Download All Attachments
                    </b-button>
                </div>
                <div class="mt-3" v-if="saveChangesSuccess">
                    Additional Notes and Invoice Document have been saved successfully.
                </div>
            </b-form>

            <!-- Error modal -->
            <Modal
                :force-open="modalError"
                header="Error"
                :headerCloseButton="true"
                @close="onCloseModalError"
            >
                <!-- Default content slot -->
                
                <div class="d-block text-center modal-text">
                    <p>
                        {{ modalErrorMessage }}
                    </p>
                </div>
                

                <template v-slot:footer>
                    <div class="text-center">
                        <b-button class="btn-yellow m-3" @click="onCloseModalError">OK</b-button>
                    </div>
                </template>
            </Modal>

            <!-- Verify modal -->
            <Modal
                :force-open="modalVerify"
                header="Please Verify"
                :headerCloseButton="true"
                @close="onCloseModalVerify"
            >
                <!-- Default content slot -->
                
                <div class="d-block text-center modal-text">
                    <p>
                        Rebate agreement 'valid to' date is {{form.validToDate}}. <br/> Please validate payment was not to be marked 'final'.
                    </p>
                </div>
                

                <template v-slot:footer>
                    <div class="text-center">
                        <b-button class="btn-yellow m-3" @click="onCloseModalVerify">Cancel & Modify</b-button>
                        <b-button class="btn-option" v-on:click="onSubmitConfirm">
                            <div class="spinner-border spinner-border-sm" role="status" v-if="loading">
                                <span class="sr-only">Loading...</span>
                            </div>
                            Submit Form
                        </b-button>
                    </div>
                </template>
            </Modal>

            <!-- Success modal -->
            <Modal
                :force-open="modalSuccess"
                header="Rebate Payment submit"
                variant="success"
                contentTitle="Success!"
                @close="onCloseModalSuccess"
            >
                <!-- Default content slot -->
                <div class="d-block text-center modal-text">
                    <p>
                        Rebate Payment {{successId}} was successfully submitted.
                    </p>
                </div>

                <template v-slot:footer>
                    <div class="text-center">
                        <b-button class="btn-option" v-on:click="btnOkSuccess">Ok</b-button>
                    </div>
                </template>
            </Modal>

            <!-- Notifications modal -->
            <Modal
                :force-open="modalNotifications"
                header="New Rebate Payment"
                :content="content"
                :variant="modalVariant"
                @close="onCloseNotifications"
            />

        </b-container>
    </div>
</template>

<script>
  import { required, requiredIf, minLength,validate} from 'vuelidate/lib/validators'
  import Modal from '../Modal'
  import { VALID_REBATE_PBU_LIST } from '../../config/constants';
  import html2pdf from "html2pdf.js";
  import { downloadAndZipFiles } from '../../lib/zip';

  export default {
    components: { Modal }, 
    data() {
        return {
            form: {
                rebateNumber: '',
                rebateNumMax: '5',
                selectedPaymentType: [], // Must be an array reference!
                commonOwnerNumber: '',
                commonOwnerName: '',
                subcommonOwnerNumber: '',
                customerGroup: '',
                rebatePbu: null,
                payerNonsig: '',
                validFromDate: '',
                validToDate: '',
                rebateTitle: '',
                paymentAmount: '',
                creditMemo: '',
                file: null,
                additionalNotes: '',
                fundingAmount: '',
                returnAmount: '',
                sendBackReason: '',
                denyReason: '',
                approverAdditionalNotes: '',
                closeRebate: 'closeRebateNo',
                salesOrderNumber: '',
                invoiceNumber: '',
                closedInSAP: '',
                approval1Type: '',
                approval2Type: '',
                additionalGbsNotes: '',
                gbsSendbackReason: '',
                paymentPeriod: '',
                invoiceDocument: '',
            },
            boxOne: '',
            show: [],
            disableForm: false,
            formValues: {},
            sendBacktoRequestor: false,
            disableRequestorFields: false,
            disableApproverFields: false,
            disableGBSFields: false,
            hideGBSFields: true, 
            requestorStatus: '',
            approverStatus: '',
            gbsStatus: '',
            items: [],
            loading: false,
            saveChangesLoading: false,
            saveChangesSuccess: false,
            searchLoading: false,
            successId: '',
            modalVerify: false,
            modalError: false,
            modalSuccess: false,
            modalNotifications: false,
            modalErrorMessage: '',
            content: '',
            modalVariant: 'success',
            isRebatePBUValidInSAP: false,
            request_type: 'RBT',
            rebateInfo: {},
            requestInfo: {},
            requestId: null,
            currentStatus: null,
            downloadAttachmentsLoading: false,
            recallLoading: false,
        }
    },
    watch: {
        'form.approverAdditionalNotes': function () {
            this.saveChangesSuccess = false;
        },
        'form.additionalGbsNotes': function () {
            this.saveChangesSuccess = false;
        },
    },
    validations: {
        form: {
            rebatePbu: { required },
            selectedPaymentType: { required },
            rebateTitle: { required },
            paymentAmount: { required },
            creditMemo: { required },
            approval1Type: { required: requiredIf(function(){
                    return !this.disableApproverFields && (this.disableRequestorFields || this.sendBacktoRequestor);
                })
            },
            sendBackReason: { required: requiredIf(function(form){
                    return form.approval1Type === 'SendBack';
                }) 
            },
            denyReason: { required: requiredIf(function(form){
                    return form.approval1Type === 'Deny';
                }) 
            },

            // GBS required fields
            approval2Type: { required: requiredIf(function(){
                    return !this.disableGBSFields && this.disableRequestorFields 
                                && this.disableApproverFields && !this.hideGBSFields;
                })
            },
            gbsSendbackReason: { required: requiredIf(function(form){
                    return form.approval2Type === 'SendBack' || form.approval2Type === 'SendBackApprover';
                }) 
            },
            salesOrderNumber: { required: requiredIf(function(form){
                    return form.approval2Type === 'Approve';
                }) 
            },
            invoiceNumber: { required: requiredIf(function(form){
                    return form.approval2Type === 'Approve';
                }) 
            },
        }
    },
    methods: {
      showMsgBoxOne() {
        this.boxOne = ''
        this.$bvModal.msgBoxOk('Action completed', {
            title: 'Confirmation',
        })
          .then(value => {
              console.log(value);
            this.boxOne = value
          })
          .catch(err => {
            // An error occurred
          })
      },
      //as a requestor, when the form submits
      onSubmit(evt) {

        if(this.validateForm()) {

            const data = this.getRequestData();

            if(data.agreement_no !== this.rebateInfo.rebateNumber) {
                this.modalErrorMessage = 'Rebate Agreement Number and Rebate Info mismatch. Please perform rebate search again.'
                this.modalError = true;

                return;
            }

            //show spinner
            this.loading = true;
            let currentDateWithFormat = new Date().toJSON().slice(0,10).replace(/-/g, "");
            let validToDateString = this.form.validToDate.replace(/-/g, "");
            //If the Requestor marks 'Partial' but the request is submitted AFTER the Valid To Date
            if ((this.form.selectedPaymentType == 'Partial') && (currentDateWithFormat > validToDateString)) {
                console.log('selected payment type is partial and alert message')
                //evt.preventDefault();
                this.modalVerify = true;
                //show error messages
                this.submitStatus = 'ERROR'
                //hide spinner
                this.loading = false;

                return;
            }
            
            this.onSubmitConfirm(evt);
            
        }
      },
      onSubmitConfirm(evt) {
          console.log('in onSubmitConfirm')
         
          if (this.validateForm()) {
            this.loading = true;
            this.submitRequest()
                .then(id => {
                    this.modalVerify = false;
                    if (id) {
                        console.log('id: ' + id);
                        this.loading = false;
                        this.successId = id;
                        this.modalSuccess = true;
                    }
                    else {
                        console.log('no id returned: ' + id);
                        this.loading = false;
                        const h = this.$createElement
                        const titleVNode = 
                            h('div', { class: [''] }, [
                                h('font-awesome-icon', { 
                                    class: ['msg-fail-icon'],
                                    props: {
                                        icon: 'times',
                                        
                                    }
                                })
                            ])
                        const messageVNode = h('div', { class: ['foobar'] }, [
                            h('p', { class: ['text-center font-weight-bold header-large'] }, [
                                'Oh no!',
                            ]),
                            h('p', { class: ['text-center modal-text'] }, [
                                'Something went wrong while attempting to submit this request.',
                            ]),
                        ])
                        this.$bvModal.msgBoxOk([messageVNode], {
                            title: [titleVNode],
                            buttonSize: 'lg',
                            centered: true,
                            okVariant: 'yellow',
                            headerClass: 'error-modal-header',
                            footerClass: 'success-modal-footer'
                        })
                    }
                })
                .catch(error => {
                    console.log(' in try catch' + error);
                    this.loading = false;
                    const h = this.$createElement
                        const titleVNode = 
                            h('div', { class: [''] }, [
                                h('font-awesome-icon', { 
                                    class: ['msg-fail-icon'],
                                    props: {
                                        icon: 'times',
                                        
                                    }
                                })
                            ])
                        const messageVNode = h('div', { class: ['foobar'] }, [
                            h('div', { class: ['text-center font-weight-bold fail-large'] }, [
                                'Oh no!',
                            ]),
                            h('p', { class: ['text-center modal-text'] }, [
                                'Something went wrong while attempting to submit this request: ',
                            ]),
                            h('p', { class: ['text-center modal-text'] }, [
                                error.message,
                            ]),
                        ])
                        this.$bvModal.msgBoxOk([messageVNode], {
                            title: [titleVNode],
                            buttonSize: 'lg',
                            centered: true,
                            okVariant: 'yellow',
                            headerClass: 'success-modal-header',
                            footerClass: 'success-modal-footer'
                        })
                });
            evt.preventDefault()
            //alert(JSON.stringify(this.form))
        }
      },
      getRebateInfo: function (event) {
        //show search spinner
        this.searchLoading = true;
        //call API to get the rebate agreement information from SAP web services
        let id = Number(this.form.rebateNumber);
        this.$store
            .dispatch('sap/getInfo', { id })
            .then(info => {
                if (info.status === '0') {
                    this.rebateInfo = info;
                    this.rebateInfo.rebateNumber = this.form.rebateNumber;
                    //console.log('getRebateInfo: ' + JSON.stringify(this.info));
                    this.form.rebateDescription = this.rebateInfo.agreement_desc;
                    this.form.validFromDate = this.rebateInfo.valid_from_date;
                    this.form.validToDate = this.rebateInfo.valid_to_date;
                    this.form.payerNonsig = this.rebateInfo.payer_nonsig;
                    this.form.commonOwnerNumber = this.rebateInfo.common_owner_number;
                    this.form.commonOwnerName = this.rebateInfo.common_owner_name;
                    this.form.subcommonOwnerNumber = this.rebateInfo.subcommon_owner_number;
                    this.form.customerGroup = this.rebateInfo.customer_group;

                    if(VALID_REBATE_PBU_LIST.includes(info.pbu_name)) {
                        this.isRebatePBUValidInSAP = true;
                        this.form.rebatePbu = this.rebateInfo.pbu_name;
                    } else {
                        this.isRebatePBUValidInSAP = false;
                        this.form.rebatePbu = null;
                    }

                } else {
                    this.content = info.message;
                    this.modalVariant = 'warn';
                    this.modalNotifications = true;
                }

                //hide search spinner
                this.searchLoading = false;
            })    
            .catch(error => {
                //hide search spinner
                this.searchLoading = false;
                console.log(error);
                this.content = error.message;
                this.modalVariant = 'error';
                this.modalNotifications = true;
            });
      },  
      onCloseModalVerify() {
        this.modalVerify = false;
      }, 
      onCloseModalError() {
        this.modalErrorMessage = '';
        this.modalError = false;
      }, 
      onCloseModalSuccess() {
        this.modalSuccess = false;
      }, 
      onCloseNotifications() {
        this.modalNotifications = false;
      },
      validateForm() {
        // check for validation errors
        this.$v.form.$touch()
        // if validation fails
        if (this.$v.form.$invalid) {
            // focus on input with error
            for (let key in Object.keys(this.$v.form)) {
                const input = Object.keys(this.$v.form)[key];
                if (input.includes("$")) return false;
                // if input is invalid and has ref
                if (this.$v.form[input].$error && this.$refs[input]) {
                    this.$refs[input].focus();
                    break;
                }
            }
            return false;
        }
        return true;
      },
      submitRequest(){
          if (this.sendBacktoRequestor) { return this.resubmitRequest()}
          else {return this.submitNewRequest()}
      },
      async resubmitRequest() {
        let params = this.getRequestData();
        let id;
        params.action = 'SBMT';
        try {
            id = params.request_id;
            console.log('In resubmitRequest ',params);
            await this.$store.dispatch('requests/update', params);        
        } catch(error){
            return Promise.reject( error );                
        }
        return Promise.resolve( id );
      },
      async submitNewRequest() {
        let id;
        const data = this.getRequestData();
        try {

            id = await this.$store.dispatch('requests/create', data);
        } catch(error){
            return Promise.reject( error );                
        }

        if (id) { 
            if (this.form.file && this.form.file.length) {
                let files = this.form.file;
                for (const file of files) {
                    let params = {
                        file: file,
                        filename: id + '/' + file.name,
                        filetype: file.type,
                        metadata: {requestid: id.toString()},
                    };
                    try {
                        await this.$store.dispatch('storage/putFile', params);
                    } catch (e) {    
                        return Promise.reject( e );
                    }
                }        
            }
        }
        return Promise.resolve( id );
      },      
      //called on form submit by requestor
      getRequestDataOLD() {
        return {
            request_type: this.request_type,
            agreement_no: this.form.rebateNumber,
            agreement_desc: this.form.rebateDescription,
            valid_from_date: this.form.validFromDate,
            valid_to_date: this.form.validToDate,
            common_owner_number: this.form.commonOwnerNumber,
            common_owner_name: this.form.commonOwnerName,
            subcommon_owner_number: this.form.subcommonOwnerNumber,
            customer_group: this.form.customerGroup,
            pbu_name: this.form.rebatePbu,
            title: this.form.rebateTitle,
            payment_type: this.form.selectedPaymentType,
            payer_nonsig: this.form.payerNonsig,
            payment_amount: this.form.paymentAmount,
            credit_memo: this.form.creditMemo,
            additional_notes: this.form.additionalNotes,
            notify_email_address: '',
            payment_period: this.form.paymentPeriod,
        }
      },
      getRequestData() {
        let queryId = this.$route.query.id;
        let id = parseFloat(queryId);
        return {
            request_type: this.request_type,
            agreement_no: this.form.rebateNumber,
            agreement_desc: this.form.rebateDescription,
            valid_from_date: this.form.validFromDate,
            valid_to_date: this.form.validToDate,
            common_owner_number: this.form.commonOwnerNumber,
            common_owner_name: this.form.commonOwnerName,
            subcommon_owner_number: this.form.subcommonOwnerNumber,
            customer_group: this.form.customerGroup,
            pbu_name: this.form.rebatePbu,
            title: this.form.rebateTitle,
            payment_type: this.form.selectedPaymentType,
            payer_nonsig: this.form.payerNonsig,
            payment_amount: this.form.paymentAmount,
            credit_memo: this.form.creditMemo,
            additional_notes: this.form.additionalNotes,
            notify_email_address: '',
            request_id: id,
            action: '',
            reason: '',
            funding_amount: this.form.fundingAmount,
            return_amount: this.form.returnAmount,
            close_sap_rebate: this.form.closeRebate,
            sales_order_number: this.form.salesOrderNumber,
            invoice_number: this.form.invoiceNumber,
            payment_period: this.form.paymentPeriod,
            invoice_document: this.form.invoiceDocument,
            recallPayment: '',
            recallReason: ''
        }
      },
      //called on page load to check if existing form exists
      getRequestInfo() {
        let queryId = this.$route.query.id;
        let id = parseFloat(queryId);
        this.requestId = id;
        let userRole = this.$store.state.auth.userRebateRoleType;
        let params = {request_id: id, filters: { request_type: this.request_type }};
        this.$store
            .dispatch('requests/getInfo', params)
            .then(info => {
                this.requestInfo = info;
                console.log('getRequestInfo: ' + JSON.stringify(this.requestInfo));

                //if the form is sent back to the requestor step
                if (this.requestInfo.form_state == 100) {
                    this.disableApproverFields = true;

                    if (this.requestInfo.requestor_user_id === this.userSession.userid || userRole === 'admin') {
                        this.sendBacktoRequestor = true;
                    } else {
                        this.sendBacktoRequestor = false;
                        this.disableRequestorFields = true;
                    }
                    if (this.requestInfo.gbs_form_action) {
                        this.hideGBSFields = false;
                        this.disableGBSFields = true;
                    }
                }
                //if the form is at the approver step
                if (this.requestInfo.form_state == 200) {
                    //disable requestor form fields
                    this.disableRequestorFields = true;

                    // User role check
                    if ( !(userRole === 'admin' || userRole === 'rebate_approver') ){
                        this.disableApproverFields = true;
                    } 

                    if (this.requestInfo.gbs_form_action) {
                        this.hideGBSFields = false;
                        this.disableGBSFields = true;
                    }
                }
                //if the form is at the GBS approver step
                if (this.requestInfo.form_state == 300) {
                    //disable requestor & approver form fields
                    this.disableRequestorFields = true;
                    this.disableApproverFields = true;
                    this.hideGBSFields = false;

                    // User role check
                    if ( !(userRole === 'admin' || userRole === 'gbs_approver') ){
                        this.disableGBSFields = true;
                    }
                }

                // if the form has been denied by approver
                if (this.requestInfo.rebate_approver_action_code == 'DENY') {
                    console.log('this should be status denied');
                    this.disableRequestorFields = true;
                    this.disableApproverFields = true;

                    if (this.requestInfo.gbs_form_action) {
                        this.hideGBSFields = false;
                        this.disableGBSFields = true;
                    }
                }
                

                //if the form has been completed or denied by GBS approver
                if (this.requestInfo.status_code == 999 || this.requestInfo.gbs_action_code === 'DENY') {
                    //disable all form fields
                    this.disableRequestorFields = true;
                    this.disableApproverFields = true;
                    this.disableGBSFields = true;
                    this.hideGBSFields = false;
                }

                console.log('this.disableRequestorFields ' + this.disableRequestorFields + 'this.disableApproverFields' + this.disableApproverFields + 'this.disableGBSFields' + this.disableGBSFields + 'this.sendBacktoRequestor' + this.sendBacktoRequestor);
                console.log('formState: ' + this.requestInfo.form_state);
                console.log('formValues: ' + JSON.stringify(this.formValues));

                this.form.rebateNumber = this.requestInfo.agreement_no;
                this.form.rebateDescription = info.agreement_desc,
                this.form.validFromDate = info.valid_from_date,
                this.form.validToDate = info.valid_to_date,
                this.form.commonOwnerNumber = info.common_owner_number,
                this.form.commonOwnerName = info.common_owner_name,
                this.form.subcommonOwnerNumber = info.subcommon_owner_number,
                this.form.customerGroup = info.customer_group,
                this.form.payerNonsig = info.payer_nonsig,
                this.form.selectedPaymentType = this.requestInfo.payment_type; // Must be an array reference!
                this.form.rebateTitle = this.requestInfo.title,
                this.form.paymentAmount = this.requestInfo.payment_amount;
                this.form.creditMemo = this.requestInfo.credit_memo;
                this.form.file = null,
                this.form.file2 = null,
                this.form.additionalNotes = this.requestInfo.requestor_additional_notes;
                this.requestorStatus = this.requestInfo.requestor_status;
                this.form.fundingAmount = this.requestInfo.funding_amount;
                this.form.returnAmount = this.requestInfo.return_amount;
                this.form.sendBackReason = this.requestInfo.rebate_approver_reason;
                this.form.denyReason = this.requestInfo.rebate_approver_reason;
                this.form.approverAdditionalNotes = this.requestInfo.rebate_approver_additional_notes;
                this.approverStatus = this.requestInfo.rebate_approver_status;
                this.form.salesOrderNumber = this.requestInfo.sales_order_number;
                this.form.invoiceNumber = this.requestInfo.invoice_number;
                this.form.closeRebate= this.requestInfo.close_sap_rebate;
                this.form.approval1Type = this.requestInfo.rebate_approver_form_action;
                this.form.approval2Type = this.requestInfo.gbs_form_action;
                this.form.additionalGbsNotes = this.requestInfo.gbs_additional_notes;
                this.form.gbsSendbackReason = this.requestInfo.gbs_reason;
                this.gbsStatus = this.requestInfo.gbs_status;
                this.form.paymentPeriod = this.requestInfo.payment_period;
                this.form.invoiceDocument = this.requestInfo.invoice_document;

                this.currentStatus = this.requestInfo.current_status;

                this.rebateInfo.rebateNumber = this.form.rebateNumber;

                if(VALID_REBATE_PBU_LIST.includes(info.pbu_name)) {
                    this.isRebatePBUValidInSAP = true;
                    this.form.rebatePbu = info.pbu_name;
                } else {
                    this.isRebatePBUValidInSAP = false;
                    this.form.rebatePbu = null;
                }

                // Additional notes
                if (this.requestInfo.form_state == 200) {
                    if (!this.requestInfo.historyRecord && this.requestInfo.additionalNotesRecord) {
                        this.form.approverAdditionalNotes = this.requestInfo.additionalNotesRecord.additional_notes;
                    }
                }
                if (this.requestInfo.form_state == 300) {
                    if (!this.requestInfo.historyRecord && this.requestInfo.additionalNotesRecord) {
                        this.form.additionalGbsNotes = this.requestInfo.additionalNotesRecord.additional_notes;
                    }
                }
            })
            //.catch(error => console.error(`Can't get rebate data with id: ${id}, error: ${JSON.stringify(error)}`));
            .catch(error => {
                console.log(error)
                this.content = error.message;
                this.modalVariant = 'error';
                this.modalNotifications = true;
            });
        params = {path: id};  
        this.$store
            .dispatch('storage/getList', params)
            .then(files => {
                console.log(files);
                this.items = files;
            })
            .catch(error => {
                console.log(error);
            });    

       },
       onFileClick(download) {
            const { key } = download;

            if (!key) {
                console.log('Can`t download document, need path to be specified');
                return null;
            }

            const params = {path: key};
            return this.$store
                .dispatch('storage/getFile', params)
                .then(result => window.open(result, '_blank'))
                .catch(error => {
                    console.log(error);
                    this.content = error.message;
                    this.modalVariant = 'error';
                    this.modalNotifications = true;
                });   
      },
      requestorResubmit: function (event) {
        let action = 'SBMT';  
      },
      recallRequest: function (event) {
        this.recallLoading = true;
        let params = this.getRequestData();  
        params.action = 'RCLL';

        if (this.requestInfo.form_state == 200) {
            params.additional_notes = this.form.approverAdditionalNotes;
        }

        if (this.requestInfo.form_state == 300) {
            params.additional_notes = this.form.additionalGbsNotes;
        }

        console.log('request params', params);
        
        this.$store
            .dispatch('requests/update', params)
            .then(() => this.$router.push({ name: 'My Rebates' }))
            .catch(error => {
                console.log(error);
                this.recallLoading = false;
                this.content = error.message;
                this.modalVariant = 'error';
                this.modalNotifications = true;
            });
        
      },
      approverSubmit: function (event) {
        if(this.validateForm()){
            this.loading = true;
            let params = this.getRequestData();  
            switch(this.form.approval1Type) {
            case 'Approve':
                params.action = 'APRV';
                params.return_amount = '';
                params.funding_amount = '';
                break;
            case 'ApproveWF':
                params.action = 'APRV';
                params.return_amount = '';
                break;
            case 'ApproveWR':
                params.action = 'APRV';
                params.funding_amount = '';
                break;
            case 'Deny':
                params.action = 'DENY';
                params.reason = this.form.denyReason;
                break;
            case 'SendBack':
                params.action = 'RRTE';
                params.reason = this.form.sendBackReason;
                break;
            default:
                // code block
            };

            //call API to submit approval fields and send to next step
            params.additional_notes = this.form.approverAdditionalNotes
            console.log('request params', params);
            this.$store
                .dispatch('requests/update', params)
                .then(() => this.$router.push({ name: 'My Rebates' }))
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                    this.content = error.message;
                    this.modalVariant = 'error';
                    this.modalNotifications = true;
                });
        }
      },      
      gbsSubmit: async function (event) {
        console.log('in gbs submit')

        if (this.validateForm()) {
            this.loading = true;
            let params = this.getRequestData();  
            switch(this.form.approval2Type) {
            case 'Approve':
                params.action = 'APRV';
                break;
            /*case 'Deny':
                params.action = 'DENY';
                params.reason = this.form.denyReason;
                break;*/
            case 'SendBack':
                params.action = 'RRTE';
                params.reason = this.form.gbsSendbackReason;
                break;
            case 'SendBackApprover':
                params.action = 'RRTA';
                params.reason = this.form.gbsSendbackReason;
                break;
            default:
                // code block
            };
            //call API to submit approval fields and send to next step
            params.additional_notes = this.form.additionalGbsNotes;
            console.log('request params', params);
            this.$store
                .dispatch('requests/update', params)
                .then(() => this.$router.push({ name: 'My Rebates' }))
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                    this.content = error.message;
                    this.modalVariant = 'error';
                    this.modalNotifications = true;
            });
        }
      },

      saveChanges: function (type) {

        const params = {
            request_id: this.requestInfo.request_id,
            form_state: this.requestInfo.form_state,
            invoice_document: this.form.invoiceDocument,
            request_type: this.request_type,
        }

        if (this.requestInfo.historyRecord) {
            params.history_id = this.requestInfo.historyRecord.history_id;
        }

        if (this.requestInfo.additionalNotesRecord) {
            params.additional_notes_id = this.requestInfo.additionalNotesRecord.additional_notes_id;
        }

        switch(type) {
            case 'approver':
                params.additional_notes = this.form.approverAdditionalNotes;
                break;
            case 'gbs':
                params.additional_notes = this.form.additionalGbsNotes;
                break;
        };

        this.saveChangesLoading = true;
        this.$store
            .dispatch('requests/saveChanges', params)
            .then(() => {
                this.saveChangesLoading = false;
                this.saveChangesSuccess = true;
            })
            .catch(error => {
                console.log(error);
                this.saveChangesLoading = false;
                this.content = error.message;
                this.modalVariant = 'error';
                this.modalNotifications = true;
        });
      },
      saveToPdf: function() {
        html2pdf(document.getElementById("rebate-payment"), {
            margin: 1,
            filename: `RBT-${this.requestId} ${this.form.rebateNumber}.pdf`,
            pagebreak: { mode: 'avoid-all' },
            html2canvas: {
                ignoreElements: (element) => {return element.tagName.toLowerCase() === 'button' && !element.classList.contains('list-group-item')}
            }
		});
      },
      clearFile1() {
        this.$refs['file-input'].reset();
      },  
      btnOkSuccess() {
        console.log('in btnOkSuccess');
        this.$router.push({path: '/my-rebates'});
      },
      resetComponentData(){
        this.form = {
            rebateNumber: '',
            rebateNumMax: '5',
            selectedPaymentType: [], // Must be an array reference!
            commonOwnerNumber: '',
            commonOwnerName: '',
            subcommonOwnerNumber: '',
            customerGroup: '',
            rebatePbu: null,
            payerNonsig: '',
            validFromDate: '',
            validToDate: '',
            rebateTitle: '',
            paymentAmount: '',
            creditMemo: '',
            file: null,
            additionalNotes: '',
            fundingAmount: '',
            returnAmount: '',
            sendBackReason: '',
            denyReason: '',
            approverAdditionalNotes: '',
            closeRebate: 'closeRebateNo',
            salesOrderNumber: '',
            invoiceNumber: '',
            closedInSAP: '',
            approval1Type: '',
            approval2Type: '',
            additionalGbsNotes: '',
            gbsSendbackReason: '',
            paymentPeriod: null,
            invoiceDocument: '',
        };
        this.rebateInfo = {};
        this.boxOne = '';
        this.show = [];
        this.disableForm = false;
        this.formValues = {};
        this.sendBacktoRequestor = false;
        this.disableRequestorFields = false;
        this.disableApproverFields = false;
        this.disableGBSFields = false;
        this.hideGBSFields = true; 
        this.requestorStatus = '';
        this.approverStatus = '';
        this.gbsStatus = '';
        this.items = [];
        this.loading = false;
        this.searchLoading = false;
        this.successId = '';
        this.modalVerify = false;
        this.modalSuccess = false;
        this.modalNotifications = false;
        this.content = '';
        this.modalVariant = 'success';
        this.isRebatePBUValidInSAP = false;
        this.requestId = null;

        // Reset validation
        this.$nextTick(() => { this.$v.$reset() })
      },
      formatRebateNumber(e){
        // Max 5 digits
        return String(e).substring(0,5);
      },

      downloadAllAttachments: async function() {
        this.downloadAttachmentsLoading = true;

        let files = await Promise.all(this.items.map( async (item) => {
            return await this.$store.dispatch('storage/getFile', { path: item.key });
        }))

        await downloadAndZipFiles(files, `RBT-${this.$route.query.id} ${this.form.rebateNumber}`);

        this.downloadAttachmentsLoading = false;
      },
    },
    mounted: function () {

        if (this.$route.query.id) {
            this.getRequestInfo();
            return;
        } 

        // Handle reset-new-rebate-data event from eventBus
        this.$eventBus.$on('reset-new-rebate-data', this.resetComponentData);

        // Force form reset after mount (to prevent Vue keeping component data after routing)
        this.resetComponentData();
        
    },
    beforeDestroy(){
        // Disable reset-new-rebate-data event handler
        this.$eventBus.$off('reset-new-rebate-data');
    },
    computed: {
        userSession() {
            return this.$store.getters['auth/userSession'];
        },
    },
  }
</script>

<style scoped>
    ::v-deep .modal-header.success-modal-header {
        background-color: #3fc59d;
        justify-content: center;
        padding: 40px 0;
    }

    ::v-deep .modal-footer.success-modal-footer {
        justify-content: center;
    }

    ::v-deep .modal-body {
        font-family: 'Barlow', sans-serif;
        font-style: normal;
        font-weight: 400;
    }

    ::v-deep .modal-header.error-modal-header {
        background-color: #c53f51; 
        justify-content: center;
        padding: 40px 0;
    }

    .modal-body .header-large {
        font-size: 28px;
    }

    .msg-success-icon {
        color: #fff;
        font-size: 48px;
    } 

    h2 {
        padding: 10px 0;
    }
    .newRebatePayment {
        margin-bottom: 40px;
    }
    .newRebatePayment .container {
        max-width: 800px;
    }

    .newRebatePayment h1 {
        margin: 20px 0;
    }

    .table-sap-data {
        margin-top: 10px;
        max-width: 525px;
    }

    .table-sap-data-overlay {
        max-width: 525px;
    }

    .rebate-details {
        font-size: 12px;
        margin-top: 10px;
    }

    .newRebatePayment legend {
        font-weight: 700;
    }

    .custom-radio label {
        font-weight: normal;
    }

    .selected-file-text {
        font-size: 12px;
    }

    #rebate-number-group .form-control {
        max-width: 210px;
        margin-right: 20px;
        margin-top: 2px;
    }

    .clear-files {
        color: #ff2534;
        font-size: 12px;
    }

    .clear-files:hover {
        color: #ff2534;
        cursor: pointer;
        text-decoration: underline;
    }

    .btn-actions-container {
        display: flex;
        margin-top: 20px;
    }

    .btn-actions-container .btn-yellow {
        margin: 0 10px; 
    }

    .btn-actions-container .btn-yellow:first-child {
        margin: 0;
    }

    .requestorSection, .approverSection {
        background-color: #dbdfe440;
        padding: 0 20px 20px;
    }

    .approverSection, .gbsSection {
        margin-top: 25px;
    }
    
    .form-group--error > label {
        color: red
    }

    .form-group--error input {
        border: 1px solid red;
    } 

    .form-group--error .error.small {
        color: red;
    }

    .validation-error {
        color: red;
    }   
    .request-header {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .request-header h1 {
        padding-top: 0px;
    }
    .btn-actions-container .btn-attachments {
        margin-right: 0px;
        margin-left: auto;
    }

    .recall-button {
        margin: 20px 0 10px 0;
    }

</style>